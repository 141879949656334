import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const NavBar = () => {
    const [nav, setNav] = useState(false);
    const links = [
        {
            id: 1,
            link : 'home'
        },
        {
            id: 2,
            link : 'about'
        },
        {
            id: 3,
            link : 'portfolio'
        },
        {
            id: 4,
            link : 'experience'
        },
        {
            id: 5,
            link : 'blogs'
        },
        {
            id: 6,
            link : 'contact'
        },
    ]
  return (
    <div className="z-50 flex justify-between items-center w-full h-20 text-white bg-gray-800 fixed px-4">
      <div>
      <Link to='home' smooth duration={500}><h1 className="lg:text-5xl md:text-4xl text-3xl font-signature ml-2 cursor-pointer">Mayank Tyagi</h1></Link>
      </div>
      <ul className="hidden md:flex">
      {
        links.map(({link, id}) => 
            <li key={id} className="px-4 cursor-pointer capitalize font-medium text-gray-300 hover:scale-105 duration-200">
            <Link to={link} smooth duration={500}>{link}</Link>
          </li>
        )
      }
      </ul>
      <div onClick={() => setNav(!nav)} className="cursor-pointer pr-4 z-10 text-gray-300 md:hidden">
       {nav ? <FaTimes size={30}/>: <FaBars size={30}/>}
      </div>
      {nav ? <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to to-gray-800 text-gray-300">
      {
        links.map(({link, id}) => 
            <li key={id} className="px-4 py-6 cursor-pointer capitalize text-4xl">
            <Link onClick={()=>{setNav(!nav)}} to={link} smooth duration={500}>{link}</Link>
          </li>
        )
      }
      </ul> : null}
    </div>
  );
};

export default NavBar;
