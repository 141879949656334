export const technologies = [
    {
        name:'HTML',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/html/html.png',
        style: 'shadow-orange-500',
    },
    {
        name:'CSS',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/css/css.png',
        style: 'shadow-blue-500',
    },
    {
        name:'JavaScript',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/javascript/javascript.png',
        style: 'shadow-yellow-500',
    },
    {
        name:'React',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png',
        style: 'shadow-blue-600',
    },
    {
        name:'JSON',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/json/json.png',
        style: 'shadow-gray-500',
    },
    {
        name:'GraphQL',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/graphql/graphql.png',
        style: 'shadow-graphql-color',
    },
    {
        name:'Bootstrap',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/bootstrap/bootstrap.png',
        style: 'shadow-purple-500',
    },
    {
        name:'Tailwind',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/tailwind/tailwind.png',
        style: 'shadow-sky-400',

    },
    {
        name:'Git',
        url: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/git/git.png',
        style: 'shadow-orange-500',
    },
]

export const projects = [
    {
        id:1,
        name: 'Task & Notes',
        src: '',
        desc: 'A simple Task & Notes app using React Native',
        url1: 'https://github.com/tmayank860/react-native-notes-app',
        url2: 'https://github.com/tmayank860/react-native-notes-app/blob/master/README.md' 
    },
    {
        id:2,
        name: 'Tesla Clone',
        src: '',
        desc: 'Tesla webapp clone using React JS',
        url1: 'https://github.com/tmayank860/tesla--react-clone',
        url2: 'https://tmayank860.github.io/tesla--react-clone/' 
    },
    {
        id:3,
        name: 'SnackGame',
        src: '',
        desc: 'SnackGame is a simple game using Vanilla JS',
        url1: 'https://github.com/tmayank860/SnakeGame',
        url2: 'https://tmayank860.github.io/SnakeGame/' 
    },
    {
        id:4,
        name:'Password Generator',
        src: '',
        desc: 'A Password generator app using Angular',
        url1: 'https://github.com/tmayank860/PasswordGenerator',
        url2: 'https://password-generator-6g5urvq76.vercel.app/' 
    },
    {
        id:4,
        name:'TypingGame',
        src: '',
        desc: 'Check your typing skills! See how fast you can complete the challenge at TypingGame using Angular',
        url1: 'https://github.com/tmayank860/TypingGame',
        url2: 'https://github.com/tmayank860/TypingGame' 
    },
    {
        id:5,
        name: 'StopWatch',
        src: '',
        desc: 'A simple StopWatch app',
        url1: 'https://github.com/tmayank860/StopWatch',
        url2: 'https://tmayank860.github.io/StopWatch/' 
    },
    
    

]